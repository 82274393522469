import { QueryNames } from 'common/models/graphql';
import Token from 'common/models/MyToken';
import { SidebarItem } from 'common/models/sidebar';
import { Table } from 'common/models/table';
import { Address, getAddress } from 'viem';

export const API_GATEWAY_URL =
  process.env.API_GATEWAY_URL || process.env.GATSBY_API_GATEWAY_URL;
if (!API_GATEWAY_URL) {
  throw new Error('To continue set the API_GATEWAY_URL environment variable');
}

export const BUILD_ENV =
  process.env.BUILD_ENV || process.env.GATSBY_BUILD_ENV || 'dev';
export const GIT_HASH =
  process.env.CF_PAGES_COMMIT_SHA ||
  process.env.GATSBY_CF_PAGES_COMMIT_SHA ||
  process.env.GIT_LONG_HASH ||
  process.env.GATSBY_GIT_LONG_HASH ||
  null;
export const BUILD_TS = process.env.BUILD_TS || process.env.GATSBY_BUILD_TS;
export const DD_APPLICATION_ID =
  process.env.DD_APPLICATION_ID || process.env.GATSBY_DD_APPLICATION_ID;
export const DD_CLIENT_TOKEN =
  process.env.DD_CLIENT_TOKEN || process.env.GATSBY_DD_CLIENT_TOKEN;
export const DD_SERVICE =
  process.env.DD_SERVICE || process.env.GATSBY_DD_SERVICE;
const CF_PAGES_BRANCH =
  process.env.CF_PAGES_BRANCH || process.env.GATSBY_CF_PAGES_BRANCH;

// Note, used to be /prod/v1/updatefarm, then created a new managed lambda which points to our geth nodes, and now the endpoint is /prod/v1/updateichi
// export const UPDATE_FARMS_URL = '/prod/v1/updatefarm';
export const UPDATE_POOL_URL = '/prod/v1/updatepool';

console.info(
  `Boostrapping build env: ${BUILD_ENV} against version ${GIT_HASH} at ${BUILD_TS} and branch: ${CF_PAGES_BRANCH}`
);

export enum ChainId {
  Mainnet = 1,
  Goerli = 5,
  Berachain_bartio = 80084,
  Blast = 81457,
  Bsc = 56,
  Celo = 42220,
  Polygon = 137,
  Skale_Europa = 2046399126,
  Mumbai = 80001,
  Arbitrum = 42161,
  ArbitrumGoerli = 421613,
}

type TokenMapping = {
  [chainId in ChainId]: Token;
};

export type Farm = {
  chainId: ChainId;
  address: Address;
  version: number;
};

type FarmMapping = {
  [chainId in ChainId]: Farm;
};

type QueryMapping = {
  [queryName in QueryNames]: number;
};

export const REFRESH_INTERVALS: QueryMapping = {
  ['listFarms']: 300000,
  ['listMonitorVaults']: 300000,
  ['listTreasuries']: 300000,
  ['getFarm']: 60000,
  ['getToken']: 60000,
  ['userInfo']: 60000,
  ['listBanners']: 300000,
};

export const MKR_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xeefBa1e63905eF1D7ACbA5a8513c70307C1cE441'),
    18,
    'ETH',
    'ETH'
  ),
  [ChainId.Berachain_bartio]: new Token(
    ChainId.Berachain_bartio,
    getAddress('0xfe0a8fc7dBb45F6FEA38F79949336a851c602912'),
    18,
    'BERA',
    'BERA'
  ),
  [ChainId.Goerli]: new Token(
    ChainId.Goerli,
    getAddress('0x77dCa2C955b15e9dE4dbBCf1246B4B85b651e50e'),
    18,
    'ETH',
    'ETH'
  ),
  [ChainId.Polygon]: new Token(
    ChainId.Polygon,
    getAddress('0x11ce4B23bD875D7F5C6a31084f55fDe1e9A87507'),
    18,
    'MATIC',
    'MATIC'
  ),
  [ChainId.Mumbai]: new Token(
    ChainId.Mumbai,
    getAddress('0x08411ADd0b5AA8ee47563b146743C13b3556c9Cc'),
    18,
    'MATIC',
    'MATIC'
  ),
  [ChainId.Arbitrum]: new Token(
    ChainId.Arbitrum,
    getAddress('0x13aD51a6664973EbD0749a7c84939d973F247921'), 
    18,
    'ETH',
    'ETH'
  ),
  [ChainId.Blast]: new Token(
    ChainId.Blast,
    getAddress('0xcA11bde05977b3631167028862bE2a173976CA11'), 
    18,
    'ETH',
    'ETH'
  ),
  [ChainId.ArbitrumGoerli]: new Token(
    ChainId.ArbitrumGoerli,
    getAddress('0x13aD51a6664973EbD0749a7c84939d973F247921'),
    18,
    'ETH',
    'ETH'
  ),
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const USDC_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48'),
    6,
    'USDC',
    'USDC'
  ),
  [ChainId.Goerli]: new Token(
    ChainId.Goerli,
    getAddress('0x2f3a40a3db8a7e3d09b0adfefbce4f6f81927557'),
    6,
    'test_usdc',
    'USDC'
  ),
  [ChainId.Polygon]: new Token(
    ChainId.Polygon,
    getAddress('0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174'),
    6,
    'pol_usdc',
    'USDC'
  ),
  [ChainId.Mumbai]: new Token(
    ChainId.Mumbai,
    getAddress('0x0FA8781a83E46826621b3BC094Ea2A0212e71B23'),
    6,
    'mum_usdc',
    'USDC'
  ),
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const DAI_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x6b175474e89094c44da98b954eedeac495271d0f'),
    18,
    'DAI',
    'DAI'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: new Token(
    ChainId.Polygon,
    getAddress('0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063'),
    18,
    'pol_dai',
    'DAI'
  ),
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// Legacy
export const ICHI_LEGACY_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x903bEF1736CDdf2A537176cf3C64579C3867A881'),
    9,
    'ICHI',
    'ICHI'
  ),
  [ChainId.Goerli]: new Token(
    ChainId.Goerli,
    getAddress('0x5c3AFa0c45aB90fE7C9ec5f962174d462D091439'),
    9,
    'ICHI',
    'ICHI'
  ),
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};
// V2
export const ICHI_NEW_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6'),
    18,
    'ICHI',
    'ICHI'
  ),
  [ChainId.Goerli]: new Token(
    ChainId.Goerli,
    getAddress('0x9b0757aCaCA5160CEBc3D16769E4f2bCe71BFbF2'),
    18,
    'ICHI',
    'ICHI'
  ),
  [ChainId.Polygon]: new Token(
    ChainId.Polygon,
    getAddress('0x111111517e4929D3dcbdfa7CCe55d30d4B6BC4d6'),
    18,
    'ICHI',
    'ICHI'
  ),
  [ChainId.Mumbai]: new Token(
    ChainId.Mumbai,
    getAddress('0x36D7A88Df8B44D966DaC25c0DB0C000AE4d2306a'),
    18,
    'ICHI',
    'ICHI'
  ),
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const XICHI_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x70605a6457B0A8fBf1EEE896911895296eAB467E'),
    9,
    'xICHI',
    'xICHI'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneICHI
// ---------------------------
export const ONE_ICHI_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x4db2c02831c9ac305FF9311Eb661f80f1dF61e07'),
    18,
    'oneichi',
    'oneICHI'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneBTC
// ---------------------------
export const ONE_BTC_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xEc4325F0518584F0774b483c215F65474EAbD27F'),
    18,
    'oneBTC',
    'oneBTC'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: new Token(
    ChainId.Polygon,
    getAddress('0x1f194578e7510A350fb517a9ce63C40Fa1899427'),
    18,
    'pol_onebtc',
    'oneBTC'
  ),
  [ChainId.Mumbai]: new Token(
    ChainId.Mumbai,
    getAddress('0xeE0de02B5aFb77aD8718bA6C24A93fF3ea4e5637'),
    18,
    'mum_onebtc',
    'oneBTC'
  ),
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const WBTC_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599'),
    18,
    'WBTC',
    'wBTC'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: new Token(
    ChainId.Polygon,
    getAddress('0x1bfd67037b42cf73acf2047067bd4f2c47d9bfd6'),
    8,
    'pol_wbtc',
    'wBTC'
  ),
  // this is the testToken6, we couldn't find wbtc on mumbai:
  [ChainId.Mumbai]: new Token(
    ChainId.Mumbai,
    getAddress('0x13EDD87281803AF4178E7b30631ab7Cbb6819441'),
    6,
    'mum_token6',
    'wBTC'
  ),
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneFIL
// ---------------------------

export const ONE_FIL_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x6d82017e55b1D24C53c7B33BbB770A86f2ca229D'),
    18,
    'onefil',
    'oneFIL'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// one1INCH
// ---------------------------
export const ONE_1INCH_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x853bb55c1f469902f088a629db8c8803a9be3857'),
    18,
    'one1inch',
    'one1INCH'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const ONEINCH_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x111111111117dC0aa78b770fA6A738034120C302'),
    18,
    '1INCH',
    '1INCH'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneFUSE
// ---------------------------
export const ONE_FUSE_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xbbce03b2e7f53cadca93251ca4c928af01db6404'),
    18,
    'onefuse',
    'oneFUSE'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const FUSE_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x970B9bB2C0444F5E81e9d0eFb84C8ccdcdcAf84d'),
    18,
    'fuse',
    'FUSE'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneGIV
// ---------------------------
export const ONE_GIV_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x17e6BA2519B4d15199B6529dB340910Ae031b1B0'),
    18,
    'onegiv',
    'oneGIV'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const GIV_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x900dB999074d9277c5DA2A43F252D74366230DA0'),
    18,
    'giv',
    'GIV'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneMPH
// ---------------------------
export const ONE_MPH_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xBE3F88E18BE3944FdDa830695228ADBB82fA125F'),
    18,
    'onemph',
    'oneMPH'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneOJA
// ---------------------------
export const ONE_OJA_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xbB9E5DB6F357BB4dF35E8B90B37b8A3F33031D86'),
    18,
    'oneoja',
    'oneOJA'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// onePERL
// ---------------------------
export const ONE_PERL_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xD9A24485e71B9148e0Fd51F0162072099DF0dB67'),
    18,
    'oneperl',
    'onePERL'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneUNI
// ---------------------------
export const ONE_UNI_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x8290d7a64f25e6b5002d98367e8367c1b532b534'),
    18,
    'oneuni',
    'oneUNI'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneDODO
// ---------------------------
export const ONE_DODO_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xcA37530E7c5968627BE470081d1C993eb1dEaf90'),
    18,
    'onedodo',
    'oneDODO'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneFOX
// ---------------------------
export const ONE_FOX_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x03352D267951E96c6F7235037C5DFD2AB1466232'),
    18,
    'onefox',
    'oneFOX'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const FOX_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0xc770EEfAd204B5180dF6a14Ee197D99d808ee52d'),
    18,
    'fox',
    'FOX'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// oneWING
// ---------------------------
export const ONE_WING_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    '0x5047fc5C9D7c49Ab22e390d13646a6A3a2476eff',
    18,
    'onewing',
    'oneWING'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

// ---------------------------
// BOOTusd
// ---------------------------
export const BOOT_USD_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: new Token(
    ChainId.Mainnet,
    getAddress('0x78a3b2f1e7eec1073088ea4a193618743f81cef8'),
    18,
    'bootusd',
    'BOOTusd'
  ),
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const OTI_TOKENS: TokenMapping = {
  [ChainId.Mainnet]: null as unknown as Token,
  [ChainId.Goerli]: null as unknown as Token,
  [ChainId.Polygon]: null as unknown as Token,
  [ChainId.Mumbai]: null as unknown as Token,
  [ChainId.Arbitrum]: null as unknown as Token,
  [ChainId.ArbitrumGoerli]: null as unknown as Token,
  [ChainId.Bsc]: null as unknown as Token,
  [ChainId.Celo]: null as unknown as Token,
};

export const FARMING_V2: FarmMapping = {
  [ChainId.Mainnet]: {
    chainId: ChainId.Mainnet,
    address: '0x275dFE03bc036257Cd0a713EE819Dbd4529739c8',
    version: 2,
  },
  [ChainId.Goerli]: null as unknown as Farm,
  [ChainId.Polygon]: {
    chainId: ChainId.Polygon,
    address: '0x2fb24195c965B4a0cDfc27DD5C85eC1A46d7A931',
    version: 2,
  },
  [ChainId.Mumbai]: {
    chainId: ChainId.Mumbai,
    address: '0x9c1c486d007B65D5cbaE45811a41E540d304ac9D',
    version: 2,
  },
  [ChainId.Arbitrum]: null as unknown as Farm,
  [ChainId.ArbitrumGoerli]: null as unknown as Farm,
  [ChainId.Bsc]: null as unknown as Farm,
  [ChainId.Celo]: null as unknown as Farm,
};

export const BERA_VAULT_WRAPPER_WITH_SLIPPAGE: FarmMapping = {
  [ChainId.Mainnet]: {
    chainId: ChainId.Mainnet,
    address: '0x980d4a48077Cde0B51c2aE19D44cc2dd0C8eFb1D',
    version: 1,
  },
  [ChainId.Goerli]: null as unknown as Farm,
  [ChainId.Polygon]: null as unknown as Farm,
  [ChainId.Mumbai]: null as unknown as Farm,
  [ChainId.Arbitrum]: {
    chainId: ChainId.Arbitrum,
    address: '0xd4493957338e3f66214c733883F789b99558758F',
    version: 1,
  },
  [ChainId.ArbitrumGoerli]: null as unknown as Farm,
  [ChainId.Bsc]: null as unknown as Farm,
  [ChainId.Celo]: null as unknown as Farm,
};

export const DEPOSIT_GUARD: FarmMapping = {
  [ChainId.Mainnet]: {
    chainId: ChainId.Mainnet,
    address: '0xe6e32D20258f475BaA8d0B39d4C391B96f0ef70A',
    version: 1,
  },
  [ChainId.Goerli]: null as unknown as Farm,
  [ChainId.Polygon]: {
    chainId: ChainId.Polygon,
    address: '0xA5cE107711789b350e04063D4EffBe6aB6eB05a4',
    version: 1,
  },
  [ChainId.Mumbai]: {
    chainId: ChainId.Mumbai,
    address: '0xA7731F9d34b9349c8f597897D0DDA2626e158935',
    version: 1,
  },
  [ChainId.Arbitrum]: {
    chainId: ChainId.Arbitrum,
    address: '0x932E1908461De58b0891E5022431dc995Cb95C5E',
    version: 1,
  },
  [ChainId.ArbitrumGoerli]: null as unknown as Farm,
  [ChainId.Berachain_bartio]: {
    chainId: ChainId.Berachain_bartio,
    address: '0x0Dad5a47adbec92E7472F6F34AC066798dEdEE40',
    version: 1,
  },
  [ChainId.Blast]: {
    chainId: ChainId.Blast,
    address: '0x8346A803e1754f9d4c6935aee1fd864Ff15955F1',
    version: 1,
  },
  [ChainId.Bsc]: {
    chainId: ChainId.Bsc,
    address: '0xE7914034D4D39087bFF0Aed442a652F5945E914b',
    version: 1,
  },
  [ChainId.Celo]: {
    chainId: ChainId.Celo,
    address: '0x62fd1824C810906F449227F1f453528bb54774C2',
    version: 1,
  },
  [ChainId.Skale_Europa]: {
    chainId: ChainId.Skale_Europa,
    address: '0x860F3881aCBbF05D48a324C5b8ca9004D31A146C',
    version: 1,
  },
};

export function getDepositGuardAddress(vaultAddress: string, chainId: ChainId): Address {
  if (vaultAddress.toLocaleLowerCase() === '0x80A86e30072e03C135EAb394dAAFc14be43F388b'.toLocaleLowerCase()){
    return '0x81B2F475e1ca7AB6b2720AdFa2fA6D4c52C4F49d' as Address;
  }
  return DEPOSIT_GUARD[chainId].address as Address;
}

export const badgeSrc = 'https://ichi-images.s3.amazonaws.com/misc/';
export const tokenSrc = 'https://ichi-images.s3.amazonaws.com/tokens/';
const sidebarSrc = 'https://ichi-images.s3.amazonaws.com/sidebar/';

//TODO add image source to config
const navIconsSrc = '../assets/image/nav-icons/';
export const chainSrc =
  'https://ichi-images.s3.amazonaws.com/chains/chain_logo_[chainid].[ext]';

// UI accepts either BERA or wBERA as a deposit tokens for these vaults
export const BERA_VAULT_WRAPPER = {
  ['0x1d451e2F5C106A26C0aAe50c7aF1920F21A15330'.toLocaleLowerCase()]:{
    vault: '0x1d451e2F5C106A26C0aAe50c7aF1920F21A15330', 
    wrapper: '0x1C3EB3c090607b6b1e598B1722B4C91847Ee8082' as Address
  }, // WBERA-HONEY
  ['0x4F2470226aB4eC9aa69f01E6fCA01aB68d2948de'.toLocaleLowerCase()]:{
    vault: '0x4F2470226aB4eC9aa69f01E6fCA01aB68d2948de', 
    wrapper: '0x3021359e1d45A43378c94112B3418bF3ab1E5982' as Address
  }, // WBERA-GOLD
  ['0x2d7622941ee8EBFc4C01fDFd8ab2d006D3D247BB'.toLocaleLowerCase()]:{
    vault: '0x2d7622941ee8EBFc4C01fDFd8ab2d006D3D247BB', 
    wrapper: '0xe48aD9fDB787ff25f7bBb400Fc993122d430C8E1' as Address
  }, // WBERA-YEET
  ['0x8b9ea50619e46E9C72289393AFc11f5256b78E63'.toLocaleLowerCase()]:{
    vault: '0x8b9ea50619e46E9C72289393AFc11f5256b78E63', 
    wrapper: '0x968bD363728e85D696b2C981a12864E32337C2d0' as Address
  }, // WBERA-OOGA
  ['0x6d501e865D112A051B42f8845E8b09Aa41765424'.toLocaleLowerCase()]:{
    vault: '0x6d501e865D112A051B42f8845E8b09Aa41765424', 
    wrapper: '0x6E2bbd6035cb8d2B97Be8e0C1e06c6B61fd1c814' as Address
  }, // WBERA-KDK
};

export const HODL_VAULT_TABLE: Table = {
  headers: [
    {
      id: '0',
      name: 'Deposit Token',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
    // {
    //   id: '1',
    //   name: 'Paired Token',
    //   displayOn: ['XL']
    // },
    {
      id: '1',
      name: '7-day APR',
      helpText: 'The annualized return from fees earned over the past 7 days',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
    // {
    //   id: '2',
    //   name: 'Total',
    //   helpText: 'Total value of liquidity for the token pair',
    //   displayOn: ['XL']
    // },
    // {
    //   id: '2',
    //   name: 'Your Deposits',
    //   helpText: '',
    //   displayOn: ['XL']
    // },
    {
      id: '2',
      name: '',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
  ],
};

export const YOUR_DEPOSITS_TABLE: Table = {
  headers: [
    {
      id: '0',
      name: 'Paired With',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
    {
      id: '1',
      name: '7-day APR',
      helpText: '7-day Fee Annual Percentage Rate',
      displayOn: ['XL'],
    },
    {
      id: '2',
      name: 'Your Value',
      helpText: '',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
    {
      id: '3',
      name: '',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
  ],
};

export const VAULT_INFO_TABLE: Table = {
  headers: [
    {
      id: '0',
      name: 'Vault',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
    // {
    //   id: '1',
    //   name: 'Current Base Price',
    //   helpText: 'Current price supported by base-position',
    //   displayOn: ['XL', 'L', 'M', 'S']
    // },
    // {
    //   id: '2',
    //   name: 'Current Max Base Price',
    //   helpText: 'Top price a base-position can support at current liquidity',
    //   displayOn: ['XL', 'L', 'M', 'S']
    // },
    // {
    //   id: '3',
    //   name: 'Minimum Strength Needed',
    //   helpText: 'Minimum Deposit Liquidity / Protected Token Market Cap needed for Vault',
    //   displayOn: ['XL']
    // },
    // {
    //   id: '4',
    //   name: 'Vault Strength',
    //   helpText: 'All liquidity deposited / protected token market cap',
    //   displayOn: ['XL']
    // },
    {
      id: '5',
      name: 'Liquidity',
      helpText: 'Total value of liquidity for the token pair',
      displayOn: ['XL', 'L', 'M', 'S'],
    },
    {
      id: '6',
      name: '',
      displayOn: ['XL'],
    },
  ],
};

export const sidebarItems: SidebarItem[] = [
  // {
  //   label: 'Vaults',
  //   path: '/',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${sidebarSrc}light/icon_lb_hodl_vault.svg` },
  //       selected: { publicURL: `${sidebarSrc}light/icon_db_hodl_vault.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${sidebarSrc}dark/icon_lb_hodl_vault.svg` },
  //       selected: { publicURL: `${sidebarSrc}dark/icon_db_hodl_vault.svg` },
  //     },
  //   },
  //   position: 'top',
  // },
  // {
  //   label: 'Home',
  //   path: '/',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${sidebarSrc}light/icon_lb_home.svg` },
  //       selected: { publicURL: `${sidebarSrc}light/icon_db_home.svg` }
  //     },
  //     dark: {
  //       default: { publicURL: `${sidebarSrc}dark/icon_lb_home.svg` },
  //       selected: { publicURL: `${sidebarSrc}dark/icon_db_home.svg` }
  //     }
  //   },
  //   position: 'top'
  // },
  /*{
    label: 'Deposit',
    path: '/deposit',
    icon: {
      light: {
        default: { publicURL: `${sidebarSrc}light/icon_lb_deposit.svg` },
        selected: { publicURL: `${sidebarSrc}light/icon_db_deposit.svg` }
      },
      dark: {
        default: { publicURL: `${sidebarSrc}dark/icon_lb_deposit.svg` },
        selected: { publicURL: `${sidebarSrc}dark/icon_db_deposit.svg` }
      }
    },
    position: 'top'
  },*/
  // {
  //   label: 'Angel Vaults',
  //   path: '/angelvault',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${sidebarSrc}light/icon_lb_angel_vault.svg` },
  //       selected: { publicURL: `${sidebarSrc}light/icon_db_angel_vault.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${sidebarSrc}dark/icon_lb_angel_vault.svg` },
  //       selected: { publicURL: `${sidebarSrc}dark/icon_db_angel_vault.svg` },
  //     },
  //   },
  //   position: 'top',
  // },
  // {
  //   label: 'Community',
  //   path: 'https://t.me/ichifarm',
  //   icon: {
  //     light: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' }
  //     },
  //     dark: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' }
  //     }
  //   },
  //   offset: '0',
  //   position: 'bottom-bottom'
  // },
  // {
  //   label: 'Discord',
  //   path: 'https://discord.gg/cxPGjGTy8V',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${navIconsSrc}discord.svg` },
  //       selected: { publicURL: `${navIconsSrc}discord.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${navIconsSrc}discord.svg` },
  //       selected: { publicURL: `${navIconsSrc}discord.svg` },
  //     },
  //   },
  //   position: 'bottom',
  // },
  {
    label: 'Docs',
    path: 'https://docs.aquabera.com',
    icon: {
      light: {
        default: { publicURL: `${navIconsSrc}docs.svg` },
        selected: { publicURL: `${navIconsSrc}docs.svg` },
      },
      dark: {
        default: { publicURL: `${navIconsSrc}docs.svg` },
        selected: { publicURL: `${navIconsSrc}docs.svg` },
      },
    },
    position: 'bottom',
  },
  // {
  //   label: 'Governance',
  //   path: 'https://docs.ichi.org/home/guides/participating-in-ichi-governance',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${navIconsSrc}governance.svg` },
  //       selected: { publicURL: `${navIconsSrc}governance.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${navIconsSrc}governance.svg` },
  //       selected: { publicURL: `${navIconsSrc}governance.svg` },
  //     },
  //   },
  //   position: 'bottom',
  // },
  // {
  //   label: 'Branded Dollar Treasury',
  //   path: '/treasury',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${navIconsSrc}treasury.svg` },
  //       selected: { publicURL: `${navIconsSrc}treasury.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${navIconsSrc}treasury.svg` },
  //       selected: { publicURL: `${navIconsSrc}treasury.svg` },
  //     },
  //   },
  //   position: 'bottom',
  // },
  // {
  //   label: 'Vault Metrics',
  //   path: '/vaultinfo',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${navIconsSrc}metrics.svg` },
  //       selected: { publicURL: `${navIconsSrc}metrics.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${navIconsSrc}metrics.svg` },
  //       selected: { publicURL: `${navIconsSrc}metrics.svg` },
  //     },
  //   },
  //   position: 'bottom',
  //   isNew: false,
  // },
  {
    label: 'Mint',
    path: '/mint',
    icon: {
      light: {
        default: { publicURL: `${navIconsSrc}mint.svg` },
        selected: { publicURL: `${navIconsSrc}mint.svg` },
      },
      dark: {
        default: { publicURL: `${navIconsSrc}mint.svg` },
        selected: { publicURL: `${navIconsSrc}mint.svg` },
      },
    },
    position: 'bottom',
  },
  // {
  //   label: 'Bridge',
  //   path: 'https://wallet.polygon.technology/',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${navIconsSrc}bridge.svg` },
  //       selected: { publicURL: `${navIconsSrc}bridge.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${navIconsSrc}bridge.svg` },
  //       selected: { publicURL: `${navIconsSrc}bridge.svg` },
  //     },
  //   },
  //   position: 'bottom',
  // },
  // {
  //   label: 'Telegram',
  //   path: 'https://t.me/ichifarm',
  //   icon: {
  //     light: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //     dark: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //   },
  //   offset: '20',
  //   position: 'smi',
  // },
  // {
  //   label: 'Discord',
  //   path: 'https://discord.gg/cxPGjGTy8V',
  //   icon: {
  //     light: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //     dark: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //   },
  //   offset: '20',
  //   position: 'smi',
  // },
  // {
  //   label: 'Twitter',
  //   path: 'https://twitter.com/ichifoundation',
  //   icon: {
  //     light: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //     dark: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //   },
  //   offset: '20',
  //   position: 'smi',
  // },
  // {
  //   label: 'Medium',
  //   path: 'https://medium.com/ichifarm',
  //   icon: {
  //     light: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //     dark: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //   },
  //   offset: '20',
  //   position: 'smi',
  // },
  // {
  //   label: 'Github',
  //   path: 'https://github.com/ichifarm',
  //   icon: {
  //     light: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //     dark: {
  //       default: { publicURL: '' },
  //       selected: { publicURL: '' },
  //     },
  //   },
  //   offset: '20',
  //   position: 'smi',
  // },
  // TODO: Update the JUGs URL
  // {
  //   label: '$JUGs',
  //   path: 'https://ally.ichi.org',
  //   icon: {
  //     light: {
  //       default: { publicURL: `${navIconsSrc}ally.svg` },
  //       selected: { publicURL: `${navIconsSrc}ally.svg` },
  //     },
  //     dark: {
  //       default: { publicURL: `${navIconsSrc}ally.svg` },
  //       selected: { publicURL: `${navIconsSrc}ally.svg` },
  //     },
  //   },
  //   position: 'bottom',
  // }
];

export const chainLogoSrc = (chainId) => {
  return `https://ichi-images.s3.amazonaws.com/chains/chain_logo_${chainId}.svg`;
}
