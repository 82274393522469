import { Wallet, getWalletConnectConnector } from '@rainbow-me/rainbowkit';
import { walletConnectWallet } from '@rainbow-me/rainbowkit/wallets';
import IconZengo from 'common/assets/image/ichifarm/zengo.svg';

interface ZenGoWalletOptions {
  projectId: string;
}

export const zenGoWallet = ({
  projectId,
}: ZenGoWalletOptions): Wallet => {
  const walletConnect = walletConnectWallet({
    projectId,
  });

  return {
    id: 'zen-go',
    name: 'ZenGo',
    iconUrl: IconZengo,
    iconBackground: '#0c2f78',
    downloadUrls: {
      android: 'https://play.google.com/store/apps/details?id=com.zengo.wallet',
      ios: 'https://apps.apple.com/us/app/zengo-crypto-bitcoin-wallet/id1440147115',
      chrome: walletConnect.downloadUrls?.chrome,
      qrCode: 'https://go.zengo.com/uCxL/74480312',
    },
    mobile: {
      getUri: (uri: string) => uri,
    },
    qrCode: {
      getUri: (uri: string) => uri,
    },
    createConnector: getWalletConnectConnector({ projectId }),
  };
};
