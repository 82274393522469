import {
  ContextWithChainId,
  ContextWithStringAmount,
  ContextWithTxHash,
  ContextWithAccountAddress,
} from 'common/models/xstate';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { VaultDepositContext } from 'xstate/vaultDepositMachine';

export namespace InputUtils {
  export function isTruthy(val: any) {
    if (isNumber(val)) {
      return val !== 0;
    }

    if (isString(val)) {
      return val.trim() !== '';
    }

    if (isBoolean(val)) {
      return val;
    }

    if (isArray(val)) {
      return val.length > 0;
    }

    return !isNull(val) && !isUndefined(val);
  }

  export function isDefaultInput<T extends ContextWithStringAmount>(
    context: T
  ) {
    return context.amount === '';
  }

  export function isZeroInput<T extends ContextWithStringAmount>(context: T) {
    if (context.amount === '') {
      return false;
    }
    return +context.amount === 0;
  }

  export function isInputTooSmall<T extends ContextWithStringAmount>(
    context: T
  ) {
    return +context.amount < 0.01;
  }

  export function isInputWithPriceTooSmall<T extends VaultDepositContext>(
    context: T
  ) {
    return +context.amount * context.tokenPrice < 0.01;
  }

  export function isVaultInputWithPriceTooSmall<T extends VaultDepositContext>(
    context: T
  ) {
    return +context.amount * context.tokenPrice < 0.01;
  }

  const decimalCount = (num) => {
    const numStr = String(num);
    // String Contains Decimal
    if (numStr.includes('.')) {
      return numStr.split('.')[1].length;
    }
    // String Does Not Contain Decimal
    return 0;
  };

  export function isTooManyDecimals9<T extends ContextWithStringAmount>(
    context: T
  ) {
    return +decimalCount(context.amount) > 9;
  }

  export function isTooManyDecimals18<T extends ContextWithStringAmount>(
    context: T
  ) {
    return +decimalCount(context.amount) > 18;
  }

  export function isInvalidInput<T extends ContextWithStringAmount>(
    context: T
  ) {
    return isUndefined(context.amount) || Number.isNaN(+context.amount);
  }

  export function isValidInput<T extends ContextWithStringAmount>(context: T) {
    return !isUndefined(context.amount) && isNumber(+context.amount);
  }

  export function alwaysTrue() {
    return true;
  }

  // TOOD: Left off here
  export function isNullChainId<T extends ContextWithChainId>(context: T) {
    if (context.chainId == null) {
      return true;
    }
    return false;
  }

  export function isNotConnected<T extends ContextWithAccountAddress>(
    context: T
  ) {
    if (!context.accountAddress) {
      return true;
    }
    return false;
  }

  export function isNullTxHash<T extends ContextWithTxHash>(context: T) {
    if (context.txHash == null) {
      return true;
    }
    return false;
  }
}
